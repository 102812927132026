import "core-js/modules/es.array.concat.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    ref: "videoReportRef",
    staticClass: "dc-video-report-container"
  }, [_c("section", {
    staticClass: "option-group"
  }, [_c("a-form-model", {
    attrs: {
      model: _vm.queryParams,
      layout: "inline"
    }
  }, [_c("a-form-model-item", [_c("a-select", {
    attrs: {
      "allow-clear": "",
      placeholder: _vm.$t("common.type")
    },
    model: {
      value: _vm.queryParams.type,
      callback: function callback($$v) {
        _vm.$set(_vm.queryParams, "type", $$v);
      },
      expression: "queryParams.type"
    }
  }, [_c("a-select-option", {
    attrs: {
      value: 1
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("common.video")) + " ")]), _c("a-select-option", {
    attrs: {
      value: 2
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("common.photo")) + " ")])], 1)], 1), _c("a-form-model-item", [_c("a-textarea", {
    attrs: {
      placeholder: "".concat(_vm.$t("postReport.orderID"), "\uFF0C").concat(_vm.$t("common.multipleRowsByEachID")),
      "auto-size": {
        minRows: 1,
        maxRows: 3
      }
    },
    model: {
      value: _vm.queryParams.ids,
      callback: function callback($$v) {
        _vm.$set(_vm.queryParams, "ids", $$v);
      },
      expression: "queryParams.ids"
    }
  })], 1), _c("a-form-model-item", [_c("a-textarea", {
    attrs: {
      placeholder: "".concat(_vm.$t("common.postID"), "\uFF0C").concat(_vm.$t("common.multipleRowsByEachID")),
      "auto-size": {
        minRows: 1,
        maxRows: 3
      }
    },
    model: {
      value: _vm.queryParams.videoids,
      callback: function callback($$v) {
        _vm.$set(_vm.queryParams, "videoids", $$v);
      },
      expression: "queryParams.videoids"
    }
  })], 1), _c("a-form-model-item", [_c("a-input", {
    attrs: {
      placeholder: _vm.$t("common.keywordSearching")
    },
    model: {
      value: _vm.queryParams.title,
      callback: function callback($$v) {
        _vm.$set(_vm.queryParams, "title", $$v);
      },
      expression: "queryParams.title"
    }
  })], 1), _c("a-form-model-item", [_c("a-select", {
    attrs: {
      "allow-clear": "",
      placeholder: _vm.$t("common.pleaseSelect")
    },
    model: {
      value: _vm.queryParams.workorderstatus,
      callback: function callback($$v) {
        _vm.$set(_vm.queryParams, "workorderstatus", $$v);
      },
      expression: "queryParams.workorderstatus"
    }
  }, [_c("a-select-option", {
    attrs: {
      value: 0
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("common.uprocessed")) + " ")]), _c("a-select-option", {
    attrs: {
      value: 1
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("common.valid")) + " ")]), _c("a-select-option", {
    attrs: {
      value: 2
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("common.invalid")) + " ")])], 1)], 1), _c("a-form-model-item", [_c("a-textarea", {
    attrs: {
      placeholder: "".concat(_vm.$t("postReport.creatorID"), "\uFF0C").concat(_vm.$t("common.multipleRowsByEachID")),
      "auto-size": {
        minRows: 1,
        maxRows: 3
      }
    },
    model: {
      value: _vm.queryParams.uids,
      callback: function callback($$v) {
        _vm.$set(_vm.queryParams, "uids", $$v);
      },
      expression: "queryParams.uids"
    }
  })], 1), _c("a-form-model-item", [_c("a-textarea", {
    attrs: {
      placeholder: "".concat(_vm.$t("postReport.reporterID"), "\uFF0C").concat(_vm.$t("common.multipleRowsByEachID")),
      "auto-size": {
        minRows: 1,
        maxRows: 3
      }
    },
    model: {
      value: _vm.queryParams.usreids,
      callback: function callback($$v) {
        _vm.$set(_vm.queryParams, "usreids", $$v);
      },
      expression: "queryParams.usreids"
    }
  })], 1), _c("a-form-model-item", [_c("a-range-picker", {
    attrs: {
      "show-time": "",
      "value-format": "YYYY-MM-DD HH:mm:ss",
      placeholder: [_vm.$t("common.beginTime"), _vm.$t("common.overTime")]
    },
    model: {
      value: _vm.queryParams.updatetime,
      callback: function callback($$v) {
        _vm.$set(_vm.queryParams, "updatetime", $$v);
      },
      expression: "queryParams.updatetime"
    }
  })], 1), _c("a-form-model-item", [_c("a-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.handleSearch
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("common.search")) + " ")]), _c("a-button", {
    staticStyle: {
      "margin-left": "10px"
    },
    on: {
      click: _vm.handleReset
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("common.reset")) + " ")])], 1)], 1)], 1), _c("section", {
    staticClass: "table-wrap"
  }, [_c("a-table", {
    attrs: {
      columns: _vm.columns,
      pagination: false,
      loading: _vm.loading,
      "data-source": _vm.dataSource,
      rowKey: _vm.rowKey,
      "row-selection": {
        selectedRowKeys: _vm.selectedRowKeys,
        onChange: _vm.onSelectChange
      }
    },
    on: {
      change: _vm.handleTableChange
    },
    scopedSlots: _vm._u([{
      key: "title1",
      fn: function fn(title, record) {
        return [_c("span", {
          staticClass: "btn-preview-video",
          on: {
            click: function click($event) {
              return _vm.handlePreview(title, record);
            }
          }
        }, [_vm._v(_vm._s(title))])];
      }
    }, {
      key: "type",
      fn: function fn(type) {
        return [type == 1 ? _c("span", [_vm._v(" " + _vm._s(_vm.$t("common.video")))]) : _c("span", [_vm._v(_vm._s(_vm.$t("common.photo")))])];
      }
    }, {
      key: "unhandlednum",
      fn: function fn(unhandlednum, _ref) {
        var id = _ref.id,
          num = _ref.num;
        return [_c("a-button", {
          attrs: {
            type: "link"
          },
          on: {
            click: function click($event) {
              return _vm.toNextPage(id);
            }
          }
        }, [_vm._v(_vm._s("".concat(unhandlednum, "/").concat(num)) + " ")])];
      }
    }, {
      key: "username",
      fn: function fn(username, _ref2) {
        var fansnum = _ref2.fansnum,
          usertype = _ref2.usertype;
        return [_c("div", [_c("span", [_vm._v(_vm._s(_vm.$t("common.nickname")))]), _vm._v("："), usertype > 0 ? _c("img", {
          staticStyle: {
            position: "relative",
            top: "-2px",
            "margin-right": "2px"
          },
          attrs: {
            src: _vm.certified,
            alt: ""
          }
        }) : _vm._e(), _vm._v(_vm._s(username) + " ")]), _c("p", {
          staticStyle: {
            "margin-top": "5px"
          }
        }, [_vm._v(" " + _vm._s(_vm.$t("common.fans")) + "："), _c("span", {
          style: _vm.setFensNumStyle(fansnum)
        }, [_vm._v(_vm._s(fansnum || 0))])])];
      }
    }, {
      key: "reportusername",
      fn: function fn(reportusername, _ref3) {
        var reportfansnum = _ref3.reportfansnum,
          reportusertype = _ref3.reportusertype;
        return [_c("div", [_c("span", [_vm._v(_vm._s(_vm.$t("common.nickname")))]), _vm._v("："), reportusertype > 0 ? _c("img", {
          staticStyle: {
            position: "relative",
            top: "-2px",
            "margin-right": "2px"
          },
          attrs: {
            src: _vm.certified,
            alt: ""
          }
        }) : _vm._e(), _vm._v(_vm._s(reportusername) + " ")]), _c("p", {
          staticStyle: {
            "margin-top": "5px"
          }
        }, [_vm._v(" " + _vm._s(_vm.$t("common.fans")) + "："), _c("span", {
          style: _vm.setFensNumStyle(reportfansnum)
        }, [_vm._v(_vm._s(reportfansnum || 0))])])];
      }
    }, {
      key: "workorderstatus",
      fn: function fn(workorderstatus) {
        return [workorderstatus == 0 ? _c("span", [_vm._v(_vm._s(_vm.$t("common.uprocessed")))]) : _vm._e(), workorderstatus == 1 ? _c("span", [_vm._v(_vm._s(_vm.$t("common.valid")))]) : _vm._e(), workorderstatus == 2 ? _c("span", [_vm._v(_vm._s(_vm.$t("common.invalid")))]) : _vm._e()];
      }
    }, {
      key: "action",
      fn: function fn(item, _ref4) {
        var videoid = _ref4.videoid,
          title = _ref4.title,
          workorderstatus = _ref4.workorderstatus;
        return [_c("div", {
          staticClass: "btn-action-group"
        }, [_c("a-button", {
          directives: [{
            name: "hasPermission",
            rawName: "v-hasPermission",
            value: ["workorder:btn:pass"],
            expression: "['workorder:btn:pass']"
          }],
          attrs: {
            disabled: workorderstatus == 1,
            ghost: "",
            type: "primary"
          },
          on: {
            click: function click($event) {
              return _vm.toggleStatus([item], 1);
            }
          }
        }, [_vm._v(" " + _vm._s(_vm.$t("common.valid")) + " ")]), _c("a-button", {
          directives: [{
            name: "hasPermission",
            rawName: "v-hasPermission",
            value: ["workorder:btn:unpass"],
            expression: "['workorder:btn:unpass']"
          }],
          attrs: {
            disabled: workorderstatus == 2,
            ghost: "",
            type: "danger"
          },
          on: {
            click: function click($event) {
              return _vm.toggleStatus([item], 2);
            }
          }
        }, [_vm._v(" " + _vm._s(_vm.$t("common.invalid")) + " ")]), _c("a-button", {
          on: {
            click: function click($event) {
              return _vm.previewLog(videoid, title);
            }
          }
        }, [_vm._v(" " + _vm._s(_vm.$t("common.logs")) + " ")])], 1)];
      }
    }])
  }), _c("pagination", {
    attrs: {
      total: _vm.total,
      page: _vm.queryParams.page,
      size: _vm.queryParams.size,
      onPageSizeChange: _vm.onPageChange,
      onShowSizeChange: _vm.onPageChange
    },
    on: {
      "update:page": function updatePage($event) {
        return _vm.$set(_vm.queryParams, "page", $event);
      },
      "update:size": function updateSize($event) {
        return _vm.$set(_vm.queryParams, "size", $event);
      }
    }
  })], 1), _c("a-modal", {
    attrs: {
      title: _vm.modalTitle,
      width: 1000,
      footer: null,
      "get-container": function getContainer() {
        return _vm.$refs.videoReportRef;
      },
      "confirm-loading": _vm.confirmLoading
    },
    model: {
      value: _vm.visible,
      callback: function callback($$v) {
        _vm.visible = $$v;
      },
      expression: "visible"
    }
  }, [_c("div", [_c("a-table", {
    attrs: {
      columns: _vm.columns2,
      pagination: false,
      scroll: {
        x: "100%",
        y: "65vh"
      },
      loading: _vm.confirmLoading,
      "data-source": _vm.dataSource2,
      rowKey: "id"
    }
  }), _c("pagination", {
    attrs: {
      total: _vm.total2,
      page: _vm.page,
      size: _vm.size,
      onPageSizeChange: _vm.onPageChange2,
      onShowSizeChange: _vm.onPageChange2
    },
    on: {
      "update:page": function updatePage($event) {
        _vm.page = $event;
      },
      "update:size": function updateSize($event) {
        _vm.size = $event;
      }
    }
  })], 1)]), _c("a-modal", {
    attrs: {
      title: _vm.modalTitle2,
      width: 600,
      okButtonProps: {
        props: {
          loading: _vm.loading
        }
      },
      okText: _vm.$t("common.confirm"),
      cancelText: _vm.$t("common.cancel"),
      "get-container": function getContainer() {
        return _vm.$refs.videoReportRef;
      }
    },
    on: {
      ok: _vm.handleOk,
      cancel: _vm.handleCancel
    },
    model: {
      value: _vm.visible2,
      callback: function callback($$v) {
        _vm.visible2 = $$v;
      },
      expression: "visible2"
    }
  }, [_c("a-form-model", {
    ref: "ruleForm",
    staticClass: "model-form",
    attrs: {
      model: _vm.formData,
      rules: _vm.rules,
      labelCol: {
        span: 6
      }
    }
  }, [_vm.actionStatus == 1 ? _c("a-form-model-item", {
    attrs: {
      label: _vm.$t("postReport.reportReason"),
      prop: "reason"
    }
  }, [_c("a-select", {
    attrs: {
      "allow-clear": "",
      placeholder: _vm.$t("postReport.chooseReportReason")
    },
    model: {
      value: _vm.formData.reason,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "reason", $$v);
      },
      expression: "formData.reason"
    }
  }, _vm._l(_vm.rejectReasons, function (item) {
    return _c("a-select-option", {
      key: item.id,
      attrs: {
        value: item.keyword
      }
    }, [_vm._v(" " + _vm._s(item.keyword) + " ")]);
  }), 1)], 1) : _vm._e(), _vm.actionStatus == 1 ? _c("a-form-model-item", {
    attrs: {
      label: _vm.$t("postReport.state")
    }
  }, [_c("a-button", {
    attrs: {
      type: "danger"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("common.reject")) + " ")])], 1) : _vm._e(), _vm.actionStatus == 1 ? _c("a-form-model-item", {
    attrs: {
      label: _vm.$t("postReport.reasonTemplate"),
      prop: "reasondesc"
    }
  }, [_c("a-select", {
    attrs: {
      "allow-clear": "",
      placeholder: _vm.$t("postReport.chooseReasonTemplate")
    },
    on: {
      change: _vm.onChange
    },
    model: {
      value: _vm.reasondesc,
      callback: function callback($$v) {
        _vm.reasondesc = $$v;
      },
      expression: "reasondesc"
    }
  }, _vm._l(_vm.rejectReasonTemps, function (item) {
    return _c("a-select-option", {
      key: item.id,
      attrs: {
        value: item.message
      }
    }, [_vm._v(" " + _vm._s(item.message) + " ")]);
  }), 1), _c("a-input", {
    attrs: {
      placeholder: _vm.$t("postReport.editReasonTemplate")
    },
    model: {
      value: _vm.formData.reasondesc,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "reasondesc", $$v);
      },
      expression: "formData.reasondesc"
    }
  })], 1) : _vm._e(), _vm.actionStatus == 2 ? _c("a-row", {
    staticStyle: {
      "margin-bottom": "20px"
    }
  }, [_c("a-col", {
    attrs: {
      span: 24,
      offset: 3
    }
  }, [_c("span", {
    staticStyle: {
      color: "red"
    }
  }, [_vm._v(_vm._s(_vm.$t("postReport.confirmDescPrefix", {
    state: _vm.$t("common.valid")
  })))]), _vm._v(" " + _vm._s(_vm.$t("postReport.confirmDescSuffix")) + " ")])], 1) : _vm._e(), _c("a-form-model-item", {
    attrs: {
      label: _vm.$t("common.remark")
    }
  }, [_c("a-input", {
    attrs: {
      placeholder: _vm.$t("postReport.enterRemark")
    },
    model: {
      value: _vm.formData.memo,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "memo", $$v);
      },
      expression: "formData.memo"
    }
  })], 1)], 1)], 1), _c("a-modal", {
    attrs: {
      width: "1000px",
      "body-style": {
        display: "flex",
        justifyContent: "center"
      },
      title: _vm.modalTitle,
      footer: null
    },
    model: {
      value: _vm.visible3,
      callback: function callback($$v) {
        _vm.visible3 = $$v;
      },
      expression: "visible3"
    }
  }, [_vm.type == 2 ? _c("div", {
    staticStyle: {
      width: "100%"
    }
  }, [_c("h3", [_vm._v(_vm._s("".concat(_vm.$t("common.total"), "\uFF1A").concat(_vm.contenturl.length)))]), _c("div", {
    staticClass: "img-list"
  }, _vm._l(_vm.contenturl, function (item, index) {
    return _c("div", {
      key: item,
      staticClass: "img-box",
      on: {
        click: function click($event) {
          return _vm.hevueImgPreview(index);
        }
      }
    }, [_c("img", {
      attrs: {
        src: item,
        alt: ""
      }
    })]);
  }), 0)]) : _c("video", {
    staticClass: "post-video",
    attrs: {
      src: _vm.contenturl,
      controls: ""
    }
  })])], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };